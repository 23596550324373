.navbar {
    background-color: #182b3a;
    background-image: linear-gradient(315deg, #182b3a 0%, #5899e2 74%);
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2); 
    border-top: .5px solid black;
    border-left: .5px solid transparent;
    border-right: .5px solid transparent;
    border-bottom: none;
    border-radius: 5px;
    overflow: hidden;
    transition: height 0.3s;
}

.show {
    height: 200px;
}

.logo {
    font-size: 2.5rem;
    padding: 5px 10px;
    background: none;
    color: white; 
  }
  
  .logo:hover {
    color: black;
    border-radius: 5px;
    padding: 10px;
  }

.logo p {
    font-size: 4rem;
}

.links {
    width: 130px;
    flex: 1;
    display: flex;
    justify-content: center;
    font-weight: 300;
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.navbar a {
    color: white;
    font-size: 1.3rem;
    margin: 5px;
    line-height: 3;
    text-decoration: none;
    border-radius: 10px;
    transition: background-color 0.3s, transform 0.2s;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 20px;
    margin-right: 20px;
    padding-left: 15px;
    padding-right: 15px;
    position: relative;
    gap: 8px; 
}


.navbar a:hover {
    color: black;
    background-image: linear-gradient(135deg, #cbf5f3 0%, #6ccccc 50%);
    transform: translateY(-5px);
}

.navbar a::before {
    content: "";
    position: absolute;
    width: 0;
    height: 2px;
    bottom: -5px;
    left: 0;
    background-color: white;
    transition: width 0.3s ease-in-out;
  }
  
.navbar a:hover::before {
    width: 100%;
  }



.hamburgerMenu {
    display: none;
}

.showMenu {
    display: flex;
}

@media (max-width: 768px) {
    .hamburgerMenu {
        display: block;
        font-size: 1.5rem;
        background: none;
        border: none;
        cursor: pointer;
    }

    .links {
        display: none;
        flex-direction: column;
        height: 40px;
        max-width: 70%;
        text-align: center;
        overflow-x: visible;
        border-radius: 20%;
    }

    .showMenu {
        display: flex;
    }

    .links a {
        display: block;
    }
}

@media (min-width: 769px) {
    .navbar {
        height: 60px; 
    }

    .links {
        display: flex; 
    }
}