.contactContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    margin: 20px;
    border: 1px solid #124e66;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    // background-color: #f9f9f9; /* Updated background color */
  }
  
  // .contactTextContainer {
  //   display: flex;
  //   flex-direction: column;
  //   align-items: center;
  //   // padding: 20px;
  //   // margin: 20px;
  //   color: #2e3944;
  //   width: 80%;
  // }
  
  // .contactTextContainer p {
  //   font-size: 25px; 
  //   color: #f9f9f9;
  // }

  .contactTextContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    padding: 10px;
    text-align: center;
  }
  
  .contactTextContainer p {
    text-align: center;
    font-size: 22px;
    color: #f9f9f9;
    line-height: 1.6;
    font-weight: 400;
    letter-spacing: 0.5px;
    text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
    padding: 15px;
    border-left: 4px solid #FFD700;  // Gold accent on the left
    background: rgba(255, 255, 255, 0.1); // Light transparency for contrast
    border-radius: 10px;
  }
  
  // .contactTextContainer p:hover {
  //   transform: scale(1.05);
  //   color: gold;
  //   text-shadow: 3px 3px 8px rgba(255, 215, 0, 0.6);
  // }
  
  
  .divider {
    width: 100%;
    margin: 20px 0;
    height: 3px;
    background: linear-gradient(to right, #FF6F61, #FFD700);
    border-radius: 5px;
  }
  
  .divider3 {
    width: 40%;
    height: 1px;
    background-color: orange;
    margin: 20px auto;
  }
  
  .contactForm { 
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
    padding: 20px;
    margin: 10px;
    border: 1px solid orange;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    background-color: #f0f9ff;
  }
  
  .contactForm p {
    font-size: 1.5em; 
    color: #124e66; 
  }
  
  .contactForm input,
  .contactForm textarea {
    text-align: center;
    width: 50%; 
    padding: 12px; 
    margin: 10px 0;
    border-radius: 8px; 
    border: 1px solid #ccc;
    font-size: 16px; 
    color: white; 
    background-color: #f0f9ff;
  }

  .contactForm textarea {
    height: 150px;
    width: 60%;
  }
  
  .contactForm input::placeholder,
  .contactForm textarea::placeholder {
    color: #333; 
  }
  
  .contactForm input:focus,
  .contactForm textarea:focus {
    border-color: #124e66;
    outline: none;
  }

  .button {
    background-color: #124e66;
    color: #fff;
    padding: 12px 24px; 
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease, color 0.3s ease;
  }
  
  .button:hover {
    background-color: #00badc;
    color: #000;
  }
  
  .messageSent {
    color: gold;
    font-size: 1.5em; 
  }
  
  .contactInfo ul {
    list-style-type: none;
    padding: 0;
  }
  
  .contactInfo li {
    margin: 10px 0;
    font-size: 1.2em;
  }
  
  .contactInfo a {
    color: #124e66;
    text-decoration: none;
    transition: color 0.3s ease;
    
  }
  
  .contactInfo a:hover {
    color: #00badc;
  }
  
  .contactInfo img {
    vertical-align: middle;
    margin-right: 8px;
    height: 20px;
    width: 20px;
  }
  
  @media (max-width: 768px) { 
    
    .contactForm {
      width: 90%;
      text-align: center;
    }
    
    .contactForm input,
    .contactForm textarea {
      width: 70%;
    }
    
    .contactInfo {
      width: 90%;
      text-align: center;
    }
  }