.homepageContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    /* background-color: #748d92; */
    padding: 20px;
    /* border: 1px solid #124e66;
    border-radius: 15px; */
    margin: 20px;
  }
  
  .homepageSlideShow {
    position: relative;
    border-radius: 15px;
    margin: 10px;
  }

  .homepageSlideShow2 {
    margin-top: 25%;
    position: relative;
    border-radius: 25px;

    /* margin: 25px; */
  }
  
  .homepageTextContainer {
    font-family: 'Roboto', sans-serif;
    font-size: 28px;
    text-align: center;
    padding: 15px;
    margin-top: 30px;
    margin-bottom: 100px;
    /* background-color: #D3D9D4; */
    /* border: 1px solid #124e66;
    border-radius: 15px; */
    /* box-shadow: 0 0 10px rgba(0, 0, 0); */
  }

.homepageTextContainer h1 {
  font-size: 45px;
  padding-bottom: 20px;
  padding-right: 0px;
  line-height: .8;
}

.heyText {
  padding-right: 100px;
}

  .homepageText {
    line-height: 1.5;
}

.verticalDivider {
  width: 1px;
  height: 50%;
  background-color: orange;
  margin: 0 20px; 
}

.divider3 {
  width: 70%;
  height: 1px;
  background-color: orange;
  margin: 20px auto;
}

  @media screen and (min-width: 769px) {
    .homepageContainer {
        flex-direction: row;
        justify-content: center;
    }

    .homepageTextContainer {
        flex: 1; 
        text-align: right;
        margin-top: 0;
    }

    .sliderContainer2 {
      width: 100%;
      height: auto;
    }
  
    .sliderImage2 {
      max-height: 300px;
      max-width: 300px;
    }
}