.sliderContainer {
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
  }
  
  .sliderImageWrapper {
    width: 500px;
    height: 500px;
    position: relative;
  }
  
  .sliderImage {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 15px;
    opacity: 0;
    transition: opacity 1s ease-in-out;
    z-index: 1;
  }
  
  .sliderImage.show {
    opacity: 1;
    z-index: 2;
  }
  
  @media screen and (max-width: 768px) {
    .sliderImageWrapper {
      width: 300px;
      height: 300px;
    }
  
    .sliderImage {
      width: 100%;
      height: 100%;
    }
  }