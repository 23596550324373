@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&family=Roboto+Condensed:ital,wght@0,100..900;1,100..900&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');


.portfolio {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px;
    padding: 20px;
    border: 1px solid #124e66;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

// .header2 {
//     font-size: 36px;
//     word-spacing: 2px;
//     font-family: 'Roboto', sans-serif;
//     font-weight: 900;
//     margin-bottom: 10px;
//     color: #f9f9f9;
//     letter-spacing: 1px;
//     margin-bottom: 40px;
// }

.header2 {
    font-family: "Inter", sans-serif;
    font-size: 32px;
    font-weight: 700;
    color: #1e506b;
    letter-spacing: 1px;
    position: relative;
    text-transform: uppercase;
    display: inline-block;
    padding-bottom: 10px;
}

.header2::after {
    content: "";
    display: block;
    width: 100%;
    height: 4px;
    background: linear-gradient(90deg, #1e2b3a, #1e2b3a);
    position: absolute;
    bottom: 0;
    left: 0;
}

.projectGrid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 20px;
    width: 100%;
    justify-items: center;
}

// .projectItem {
//     // background-color: #f9f9f9;
//     border: .5px solid #124e66;
//     padding: 20px;
//     border-radius: 10px;
//     box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
//     transition: transform 0.3s ease, box-shadow 0.3s ease;
// }

// .projectItem:hover {
//     transform: scale(1.05);
//     box-shadow: 0 22px 24px rgba(0, 0, 0, 0.2);
// }

.projectItem {
    background: rgba(255, 255, 255, 0.8);
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease-in-out;
    border-left: 4px solid #5899e2; 

    &:hover {
        background: rgba(255, 255, 255, 0.9);
        transform: scale(1.02);
    }
}

.projectItem a {
    font-family: "Roboto Condensed", sans-serif;
    font-size: 22px;
    font-weight: 700;
    color: #124e66;
    text-decoration: none;
    transition: color 0.3s ease-in-out;

    &:hover {
        color: #1f78a7;
    }
}

.portfolio p {
    margin-bottom: 1px;
    font-family: 'Roboto', sans-serif;
    font-weight: 450;
    font-size: 16px;
    text-align: center;
    color: #333;
}

.divider {
    width: 100%;
    margin: 20px 0;
    height: 3px;
    background: linear-gradient(to right, #FF6F61, #FFD700);
    border-radius: 5px;
}

.divider2 {
    width: 50%;
    height: 1px;
    background-color: orange;
    margin: 20px 0;
}

.link {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: larger;
    padding-bottom: 10px;
    color: white;
    padding: 10px 20px;
    background-color: #b0c8e1;
    color: white;
    border: none;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.link:hover {
    background-color: #7CB9E8;
}

@media (max-width: 768px) {
    .header2 {
        text-align: center;
    }
}