:root {
  font-family: Inter, system-ui, Avenir, Helvetica, Arial, sans-serif;
  line-height: 1.5;
  font-weight: 400;
  
  color-scheme: light dark;
  color: #2E3944;
  background-color: #ffffff;
  background-image: linear-gradient(315deg, #ffffff 0%, #5899e2 74%);
  
  font-synthesis: none;
  text-rendering: optimizeLegibility;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

a {
  font-weight: 500;
  color: #646cff;
  text-decoration: inherit;
}

a:hover {
  color: #535bf2;
}

// h1 {
//   font-size: 3.2em;
//   line-height: 1.1;
// }

h1 {
  font-family: "Montserrat", sans-serif;
  font-size: 48px;
  font-weight: 900;
  letter-spacing: 2px;
  background: linear-gradient(45deg, #0b161a, #1e506b);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
  margin-bottom: 20px;
}

button {
  border-radius: 8px;
  border: 1px solid transparent;
  padding: 0.6em 1.2em;
  font-size: 1em;
  font-weight: 500;
  font-family: inherit;
  cursor: pointer;
  transition: background-color 0.3s ease, border-color 0.25s;
}

button:hover {
  border-color: #646cff;
}

button:focus,
button:focus-visible {
  outline: 4px auto -webkit-focus-ring-color;
}

// /* Light Mode Styles */
// body.light {
//   background-color: #ffffff;
//   color: #213547;
//   background-image: linear-gradient(315deg, #ffffff 0%, #5899e2 74%);
// }

// body.light button {
//   background-color: #f9f9f9;
//   color: #000;
// }

// /* Dark Mode Styles */
// body.dark {
//   background-color: #2E3944;
//   color: #f9f9f9;
//   background-image: linear-gradient(315deg, #2e3944 0%, #3c4f63 74%);
// }

// body.dark button {
//   background-color: #333;
//   color: #f9f9f9;
// }