.sliderContainer2 {
    width: 80%;
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    overflow: hidden;
  }
  
  .sliderImageWrapper2 {
    width: 300px;
    height: 300px;
    position: relative;
  }
  
  .sliderImage2 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50px;
    opacity: 0;
    transition: opacity 1s ease-in-out;
    z-index: 1;
  }
  
  .sliderImage2.show {
    opacity: 1;
    z-index: 2;
  }
  
  @media screen and (max-width: 768px) {
    .sliderImageWrapper2 {
      max-width: 100%;
      height: auto;
    }
  
    .sliderImage2 {
      border-radius: 20px;
    }
  }