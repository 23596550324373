
.resumeContainer {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 20px;
    border: 1px solid #124e66;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0);
    margin: 20px;
    flex-direction: column;
    
}

.backgroundContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #f9f9f9; 
    // padding: 20px;
    border-radius: 10px;
    max-width: 75%;
    font-family: 'Poppins', sans-serif;
    
    // background-color: #ffffff;
    // background-image: linear-gradient(315deg, #ffffff 0%, #5899e2 74%);
    // box-shadow: 0 0 10px rgba(0, 0, 0);
}

// .resumeContainer h1 {
//     display: flex;
//     font-size: 38px; 
//     color: #124e66; 
//     margin-bottom: 20px; 
// }

.backgroundContainer h3 {
    text-align: center;
    margin-bottom: 10px;
    font-size: 30px; 
    color: #f9f9f9;
    text-transform: uppercase; 
}

// .backgroundContainer p {
//     text-align: center;
//     font-size: 25px; 
//     color: #f9f9f9;
//     line-height: 1.5; 
//     font-weight: 400; 
// }

.backgroundContainer p {
    text-align: center;
    font-size: 22px;
    color: #f9f9f9;
    line-height: 1.6;
    font-weight: 400;
    letter-spacing: 0.5px;
    text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.2);
    padding: 10px;
    border-left: 4px solid #FFD700;  // Gold accent on the left
    background: rgba(255, 255, 255, 0.1); // Light transparency for contrast
    border-radius: 10px;
}


.resumeContent h2 {
    font-size: 28px; 
    color: #f9f9f9;
    margin-bottom: 15px; 
}

.resumeContent {
    display: flex;
    flex-direction: column;
    align-items: center;
    // padding: 20px;
}

.divider {
    width: 100%;
    margin: 20px 0;
    height: 3px;
    background: linear-gradient(to right, #FF6F61, #FFD700);
    border-radius: 5px;
}

.divider3 {
    width: 40%;
    height: 1px;
    // background-color: #124e66;
    background-color: orange;
    // margin: 20px auto;
}


.resumeContainer2 {
    display: flex;
    margin-top: 25px;
    margin-bottom: 25px;
    border: 1px solid #124e66;
    border-radius: 10px;
    box-shadow: 10px 10px 10px rgba(0, 0, 0, 0.3);
    // background-color: #182b3a;
    // background-image: linear-gradient(315deg, #182b3a 0%, #20a4f3 74%);
    // color: #2a52be;
    color: #1E90FF
}

.resumeColumn {
    flex: 1;
    margin: 0 10px;
    padding-left: 35px;
    padding-right: 35px;
}

.resumeColumn h2 {
    font-size: 24px;
    margin-bottom: 10px;
}

.resumeColumn ul {
    list-style-type: none;
}

.resumeColumn li {
    margin-bottom: 5px;
}

.resumeButton {
    display: inline-block;
    padding: 10px 20px;
    background-color: #3498db;
    color: whitesmoke;
    font-size: 16px;
    text-transform: uppercase;
    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.5s;
    height: 30px;
    align-content: center;
    margin-bottom: 25px;
    box-shadow: 0 0 10px rgba(0, 0, 0);
}

@media (max-width: 768px) { 
.resumeContainer2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 15px;
    margin: 20px;
    margin-left: 10px;
    margin-right: 10px;
    border: 1px solid #124e66;
    border-radius: 10px;
}

.resumeColumn:after { 
    width: 50%;
    height: 1px;
    background-color: #124e66;
    margin: 20px 0;
}

.backgroundContainer {
    max-width: 95%;
}
}